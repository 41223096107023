/*
<svg fill="#000000" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" width="256px" height="256px">
<g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.24000000000000005"></g>
<g id="SVGRepo_iconCarrier"> <style type="text/css"> .st0{fill:none;} </style> <path d="M11.8,2c-0.2,0-0.4,0.1-0.5,0.2L9.9,3.5L8.3,3.2C7.8,3.1,7.5,3.4,7.4,3.8L7.1,5.6L5.4,6.4C5,6.6,4.9,7,5.1,7.4L5.9,9 l-0.8,1.6c-0.2,0.4,0,0.8,0.3,1l1.7,0.8l0.3,1.8c0.1,0.4,0.5,0.7,0.9,0.6l1.9-0.3l1.4,1.3c0.3,0.3,0.8,0.3,1.1,0l1.4-1.3l1.9,0.3 c0.4,0.1,0.8-0.2,0.9-0.6l0.3-1.8l1.7-0.8c0.4-0.2,0.5-0.6,0.3-1L18.1,9l0.8-1.6c0.2-0.4,0.1-0.8-0.3-1l-1.7-0.8l-0.3-1.8 c-0.1-0.4-0.5-0.7-0.9-0.6l-1.9,0.3l-1.4-1.3C12.3,2,12,2,11.8,2z M6.1,13.1L3,19.3l3.6-0.5L8.4,22l2.6-5.3l-0.2-0.2l-1-0.9 l-1.4,0.2c-0.9,0.1-1.8-0.5-2-1.4L6.1,13.1L6.1,13.1z M17.9,13.1l-0.3,1.3c-0.2,0.8-0.9,1.4-1.7,1.4h-0.2l-1.4-0.2l-1,0.9l-0.2,0.2 l2.6,5.3l1.8-3.1l3.6,0.5L17.9,13.1z"></path> <rect class="st0" width="24" height="24"></rect> </g></svg>
*/
import { Icon } from 'types/Icon';

export const SslCertificateIcon = ({ color, width, height }: Icon) => (
  <svg width={width || '24'} height={height || '24'} viewBox='0 0 24 24'>
    <defs>
      <path
        // eslint-disable-next-line max-len
        d='M11.8,2c-0.2,0-0.4,0.1-0.5,0.2L9.9,3.5L8.3,3.2C7.8,3.1,7.5,3.4,7.4,3.8L7.1,5.6L5.4,6.4C5,6.6,4.9,7,5.1,7.4L5.9,9 l-0.8,1.6c-0.2,0.4,0,0.8,0.3,1l1.7,0.8l0.3,1.8c0.1,0.4,0.5,0.7,0.9,0.6l1.9-0.3l1.4,1.3c0.3,0.3,0.8,0.3,1.1,0l1.4-1.3l1.9,0.3 c0.4,0.1,0.8-0.2,0.9-0.6l0.3-1.8l1.7-0.8c0.4-0.2,0.5-0.6,0.3-1L18.1,9l0.8-1.6c0.2-0.4,0.1-0.8-0.3-1l-1.7-0.8l-0.3-1.8 c-0.1-0.4-0.5-0.7-0.9-0.6l-1.9,0.3l-1.4-1.3C12.3,2,12,2,11.8,2z M6.1,13.1L3,19.3l3.6-0.5L8.4,22l2.6-5.3l-0.2-0.2l-1-0.9 l-1.4,0.2c-0.9,0.1-1.8-0.5-2-1.4L6.1,13.1L6.1,13.1z M17.9,13.1l-0.3,1.3c-0.2,0.8-0.9,1.4-1.7,1.4h-0.2l-1.4-0.2l-1,0.9l-0.2,0.2 l2.6,5.3l1.8-3.1l3.6,0.5L17.9,13.1z'
        id='path-SslCertificateIcon-1'
      />
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-483.000000, -3298.000000)'>
        <g transform='translate(120.000000, 3107.000000)'>
          <g transform='translate(363.000000, 191.000000)'>
            <use fill={color || 'currentColor'} xlinkHref='#path-SslCertificateIcon-1' />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
