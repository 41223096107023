import Qs from 'qs';
import axios, { AxiosInstance } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useAccessToken } from 'hooks';
import { AccessToken } from 'types/Auth';
import { IPAIAdminApiClient, PAIAdminApiClient } from 'common/api/generated/PAIAdminApiClient';
import { IPAITransformationApiClient, PAITransformationApiClient } from 'common/api/generated/PAITransformationApiClient';
import { IPAIPropellApClient, PAIPropellApClient } from 'common/api/generated/PAIPropellApClient';
import { PAIXledgerApiClient, IPAIXledgerApiClient } from 'common/api/generated/PAIXledgerApiClient';

// TODO - export from config?
const integrationApiUrl = process.env.REACT_APP_API_URL || '';

type TypedClient<TClientInterface> = { new (baseUrl?: string, instance?: AxiosInstance): TClientInterface };
type TypedClientInfo<TClientInterface> = {
  paiClientType: TypedClient<TClientInterface>;
  apiUrlPrefix?: string;
};

const createTypedClient = <TClientInterface>(clientInfo: TypedClientInfo<TClientInterface>, accessToken: AccessToken) =>
  new clientInfo.paiClientType(
    undefined,
    axios.create({
      baseURL: integrationApiUrl + '/' + (clientInfo.apiUrlPrefix || ''),
      headers: { Authorization: `Bearer ${accessToken}` },
      paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'repeat' }),
      // For settings.TypeScriptGeneratorSettings.TypeStyle =
      // NJsonSchema.CodeGeneration.TypeScript.TypeScriptTypeStyle.Interface
      // https://github.com/RicoSuter/NSwag/issues/3294
      transformResponse: (data) => data,
    }),
  );

const useAuthenticatedTypedClient = <TClientInterface>(clientInfo: TypedClientInfo<TClientInterface>) => {
  const clientInfoRef = useRef(clientInfo);
  const accessToken = useAccessToken();
  const [paiClient, setPaiClient] = useState<TClientInterface>();

  useEffect(() => {
    setPaiClient(accessToken === null ? undefined : createTypedClient(clientInfoRef.current, accessToken));
  }, [accessToken]);

  return paiClient;
};

// these are meant for use in sagas or where hooks are not possible to use

export const createAdminApiClient = (accessToken: AccessToken) =>
  createTypedClient<IPAIAdminApiClient>({ paiClientType: PAIAdminApiClient, apiUrlPrefix: '' }, accessToken);

export const createTransformationApiClient = (accessToken: AccessToken) =>
  createTypedClient<IPAITransformationApiClient>({ paiClientType: PAITransformationApiClient, apiUrlPrefix: 'transformations' }, accessToken);

export const createPropellApClient = (accessToken: AccessToken) =>
  createTypedClient<IPAIPropellApClient>({ paiClientType: PAIPropellApClient, apiUrlPrefix: 'propellap' }, accessToken);

export const createXledgerApiClient = (accessToken: AccessToken) =>
  createTypedClient<IPAIXledgerApiClient>({ paiClientType: PAIXledgerApiClient, apiUrlPrefix: 'xledger' }, accessToken);

// these are meant for use in components

export const useAuthenticatedAdminApiClient = () =>
  useAuthenticatedTypedClient<IPAIAdminApiClient>({ paiClientType: PAIAdminApiClient, apiUrlPrefix: '' });

export const useAuthenticatedTransformationApiClient = () =>
  useAuthenticatedTypedClient<IPAITransformationApiClient>({ paiClientType: PAITransformationApiClient, apiUrlPrefix: 'transformations' });

export const useAuthenticatedPropellApClient = () =>
  useAuthenticatedTypedClient<IPAIPropellApClient>({ paiClientType: PAIPropellApClient, apiUrlPrefix: 'propellap' });

export const useAuthenticatedXledgerApiClient = () =>
  useAuthenticatedTypedClient<IPAIXledgerApiClient>({ paiClientType: PAIXledgerApiClient, apiUrlPrefix: 'xledger' });
