import * as React from 'react';
import { convertLocalDateToUtc } from 'utils/formatDate';
import { FULL_DATE_TIME_FORMAT } from 'common/constants/DateTimeFormats';
import {
  Message,
  Modal,
  Button,
  DatePicker,
  TooltipCustom,
  CloseIcon,
  SettingsIcon,
  SyncIcon,
  CalendarIcon,
  Alert,
} from 'common/components';
import {
  FormButtonsCentered,
  ModalSection,
  ModalSelectedClients,
  ModalSelectedClient,
  ModalSelectedClientRemoveButton,
  ModalSelectFromDateTitle,
  ModalSelectFromDateTitleTooltip,
  ModalSelectStrategyOptions,
  ModalSelectStrategyOption,
  ModalSelectStrategyOptionIcon,
  ModalSelectStrategyOptionText,
  ModalSelectStrategyAlert,
} from '../styles';

type SyncMasterDataModalProps = {
  isVisible: boolean;
  selectedClients: any[];
  onRemoveClient: (client: any) => void;
  onModalCancelHandler: () => void;
  onModalConfirmHandler: (fromDate?: Date) => void;
};

type SyncMasterDataModalStrategy = 'default' | 'full' | 'date';

const getSyncFromDateFromStrategy = (strategy: SyncMasterDataModalStrategy, syncFromDate?: Date) => {
  switch (strategy) {
    case 'default':
      return undefined;
    case 'full':
      return new Date(1998, 0);
    case 'date':
      return syncFromDate;
  }
};

const SyncMasterDataModal = ({
  isVisible,
  selectedClients,
  onModalCancelHandler,
  onModalConfirmHandler,
  onRemoveClient,
}: SyncMasterDataModalProps) => {
  const [selectedStrategy, setSelectedStrategy] = React.useState<SyncMasterDataModalStrategy>('default');
  const [syncFromDate, setSyncFromDate] = React.useState<Date | undefined>();

  const onConfirmHandler = React.useCallback(() => {
    const fromDate = getSyncFromDateFromStrategy(
      selectedStrategy,
      syncFromDate ? convertLocalDateToUtc(syncFromDate) : undefined,
    );
    onModalConfirmHandler(fromDate);
    setSelectedStrategy('default');
    setSyncFromDate(undefined);
  }, [onModalConfirmHandler, selectedStrategy, syncFromDate]);

  const onCancelHandler = React.useCallback(() => {
    onModalCancelHandler();
    setSelectedStrategy('default');
    setSyncFromDate(undefined);
  }, [onModalCancelHandler]);

  const footerButtons = React.useMemo(
    () => (
      <FormButtonsCentered>
        <Button transparent onClick={onCancelHandler}>
          <Message id='cancel-button' />
        </Button>
        <Button primary onClick={onConfirmHandler} disabled={selectedStrategy === 'date' && !syncFromDate}>
          <Message id='customer-sync-master-data-sync-button' />
        </Button>
      </FormButtonsCentered>
    ),
    [onCancelHandler, onConfirmHandler, selectedStrategy, syncFromDate],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      withScroll
      hideOnOverlayClicked={false}
      closeOnEsc={false}
      isVisible={isVisible}
      title={<Message id='customer-sync-master-data-modal-title' />}
      size='large'
      onCloseClicked={onModalCancelHandler}
      footer={footerButtons}
    >
      <ModalSection>
        <h3>
          <Message id='customer-sync-master-data-modal-text' />
        </h3>
        <ModalSelectedClients>
          {selectedClients.map((client: any) => (
            <ModalSelectedClient key={client.clientId} onlySelected={selectedClients.length === 1}>
              {client.clientName} <strong>{`(${client.organizationNumber})`}</strong>
              {selectedClients.length > 1 && (
                <ModalSelectedClientRemoveButton role='button' onClick={() => onRemoveClient(client)}>
                  <CloseIcon height={10} width={10} />
                </ModalSelectedClientRemoveButton>
              )}
            </ModalSelectedClient>
          ))}
        </ModalSelectedClients>
      </ModalSection>
      <ModalSection>
        <ModalSelectFromDateTitle>
          <Message id='customer-sync-master-data-choose-from-date-modal-text' />
          <TooltipCustom
            placement='top'
            title={<Message id='customer-sync-master-data-choose-from-date-modal-tooltip' />}
          >
            <ModalSelectFromDateTitleTooltip>?</ModalSelectFromDateTitleTooltip>
          </TooltipCustom>
        </ModalSelectFromDateTitle>
        <ModalSelectStrategyOptions>
          <ModalSelectStrategyOption
            aria-selected={selectedStrategy === 'default'}
            selected={selectedStrategy === 'default'}
            onClick={() => setSelectedStrategy('default')}
          >
            <ModalSelectStrategyOptionIcon>
              <SettingsIcon width={24} height={24} />
            </ModalSelectStrategyOptionIcon>
            <ModalSelectStrategyOptionText>Default Behavior</ModalSelectStrategyOptionText>
          </ModalSelectStrategyOption>
          <ModalSelectStrategyOption
            aria-selected={selectedStrategy === 'full'}
            selected={selectedStrategy === 'full'}
            onClick={() => setSelectedStrategy('full')}
          >
            <ModalSelectStrategyOptionIcon>
              <SyncIcon width={24} height={24} />
            </ModalSelectStrategyOptionIcon>
            <ModalSelectStrategyOptionText>Full Sync</ModalSelectStrategyOptionText>
          </ModalSelectStrategyOption>
          <ModalSelectStrategyOption
            aria-selected={selectedStrategy === 'date'}
            selected={selectedStrategy === 'date'}
            onClick={() => setSelectedStrategy('date')}
          >
            <ModalSelectStrategyOptionIcon>
              <CalendarIcon width={24} height={24} />
            </ModalSelectStrategyOptionIcon>
            <ModalSelectStrategyOptionText>From Date</ModalSelectStrategyOptionText>
          </ModalSelectStrategyOption>
        </ModalSelectStrategyOptions>
        {selectedStrategy === 'date' && (
          <DatePicker
            date={syncFromDate}
            onChange={setSyncFromDate}
            maxDate={new Date()}
            showTimeSelect={true}
            dateFormat={FULL_DATE_TIME_FORMAT}
            leftIcon={<CalendarIcon />}
            placeholderText='Select from date'
            label={<p>Only Master Data newer than this date will be synced.</p>}
          />
        )}
        {selectedStrategy === 'full' && (
          <ModalSelectStrategyAlert>
            <Alert
              title='Full sync is selected'
              text='This will perform a full Master Data sync for the selected client(s). This might take longer.'
            />
          </ModalSelectStrategyAlert>
        )}
        {selectedStrategy === 'date' && syncFromDate && (
          <ModalSelectStrategyAlert>
            <Alert
              title='Sync from date is selected'
              text='If the ERP integration supports it, will sync Master Data newer than the selected date, otherwise will use default behavior. This might take longer depending on how far back the date goes.'
            />
          </ModalSelectStrategyAlert>
        )}
      </ModalSection>
    </Modal>
  );
};

export default SyncMasterDataModal;
