import { ReactNode, useState } from 'react';
import { ReactDatePickerProps } from 'react-datepicker';
import { SHORT_DATE_FORMAT } from 'common/constants';
import {
  DateTimeContainer,
  DateTimeHolder,
  DateTimeWrap,
  DatePickerStyled,
  Label,
  TextErrorMessage,
  TextLeftIcon,
  TextRightIcon,
} from './styles';
import 'react-datepicker/dist/react-datepicker.css';
import './customStyles.css';

interface DatePickerProps extends ReactDatePickerProps {
  isError?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  withMonthYearSelect?: boolean;
  showTimeSelect?: boolean;
  dateFormat?: string;
  timeFormat?: string;
  timeIntervals?: number;
  minDate?: Date;
  maxDate?: Date;
  startDate?: Date | null;
  endDate?: Date | null;
  date?: Date | null;
  label?: ReactNode;
  errorText?: ReactNode;
  hintText?: ReactNode;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  placeholderText?: string;
  handleOnFocusDateTime?: () => void;
  onChange: (payload: any) => void;
  onSelect?: (payload: any) => void;
  requestOnClose?: () => void;
}

const DatePicker = ({
  isError,
  isDisabled,
  isClearable,
  withMonthYearSelect,
  dateFormat = SHORT_DATE_FORMAT,
  minDate,
  maxDate,
  startDate,
  endDate,
  date,
  label,
  errorText,
  leftIcon,
  rightIcon,
  placeholderText,
  onChange,
  onSelect,
  popperPlacement,
  selectsRange,
  showTimeSelect,
  timeFormat,
  timeIntervals,
  requestOnClose,
}: DatePickerProps) => {
  const [isFocused, setFocused] = useState(false);

  const handleCalendarOpen = () => setFocused(!isFocused);
  const handleCalendarClose = () => {
    if (requestOnClose) {
      requestOnClose();
    }

    return setFocused(!isFocused);
  };

  const renderLeftIcon = () => <TextLeftIcon>{leftIcon}</TextLeftIcon>;
  const renderRightIcon = () => <TextRightIcon>{rightIcon}</TextRightIcon>;

  const renderErrorText = () => <TextErrorMessage isError={isError}>{errorText}</TextErrorMessage>;

  return (
    <DateTimeContainer>
      <Label isDisabled={isDisabled} isError={isError}>
        {label}
      </Label>
      <DateTimeHolder>
        {leftIcon && renderLeftIcon()}
        {rightIcon && renderRightIcon()}
        <DateTimeWrap isError={isError}>
          <DatePickerStyled
            selectsRange={selectsRange}
            disabled={isDisabled}
            isError={isError}
            isFocused={isFocused}
            leftIcon={leftIcon}
            selected={date}
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={maxDate}
            dateFormat={dateFormat}
            popperPlacement={popperPlacement}
            placeholderText={placeholderText}
            onChange={onChange}
            onSelect={onSelect}
            popperClassName='propell-calendar-popup'
            onCalendarClose={handleCalendarClose}
            onCalendarOpen={handleCalendarOpen}
            showMonthDropdown={withMonthYearSelect}
            showYearDropdown={withMonthYearSelect}
            scrollableMonthYearDropdown={withMonthYearSelect}
            scrollableYearDropdown={withMonthYearSelect}
            isClearable={isClearable}
            showTimeSelect={showTimeSelect}
            timeFormat={timeFormat}
            timeIntervals={timeIntervals}
          />
          {isError && errorText && renderErrorText()}
        </DateTimeWrap>
      </DateTimeHolder>
    </DateTimeContainer>
  );
};

export default DatePicker;
