import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { Controller, useForm } from 'react-hook-form';
import { useAuthenticatedXledgerApiClient, useRemoteApiCall } from 'hooks';
import { Button, Input, Message, Modal, Spinner } from 'common/components';
import { XledgerEnvironmentType } from 'common/api/generated/PAIXledgerApiClient';
import { NotificationType } from 'types/Notifications';
import { actionCreators as notificationsActionCreators } from 'components/NotificationManager/logic/notificationReducer';
import { SslCertificateIcon } from 'common/components/Icons/SslCertificate';
import {
  FormButtons,
  FormColumn,
  FormColumnWrapper,
  FormRow,
  FormWrapper,
} from './styles';

const defaultValues = {
  logonId: '',
  password: '',
  environmentType: '',
  securityCode: '',
};

interface XledgerCertificateFormProps {
  disabled: boolean;
  onCertificateAcquiredCallback: (payload: { certificateDataEncoded: string; certificatePassword: string }) => void;
}

const XledgerCertificateForm = ({ onCertificateAcquiredCallback, disabled }: XledgerCertificateFormProps) => {

  const xledgerApiClient = useAuthenticatedXledgerApiClient();
  const dispatcher: Dispatch = useDispatch();
  const [isFetching, callRemoteApi] = useRemoteApiCall();
  const [isFormDisabled, setFormDisabled] = useState(false);
  const [isFormModalVisible, setFormModalVisible] = useState(false);
  const [securityCodeSent, setSecurityCodeSent] = useState(false);

  useEffect(() => {
    setFormDisabled(isFetching);
  }, [isFetching]);

  // security code form
  const {
    handleSubmit: securityCodeFormHandleSubmit,
    formState: { isValid: securityCodeFormIsValid, isDirty: securityCodeFormIsDirty },
    control: securityCodeFormControl,
    reset: securityCodeFormReset,
    getValues: getSecurityCodeFormValues,
  } = useForm({ mode: 'onChange' });

  // certificate form
  const {
    handleSubmit: certificateFormHandleSubmit,
    formState: { isValid: certificateFormIsValid, isDirty: certificateFormIsDirty },
    control: certificateFormControl,
    reset: certificateFormReset,
  } = useForm({ mode: 'onChange' });

  const onAcquireCertificateClickHandler = useCallback(() => {
    setFormModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    securityCodeFormReset({ logonId: '', password: '', environmentType: '' });
    certificateFormReset({securityCode: ''});
    setFormModalVisible(false);
  }, [securityCodeFormReset, certificateFormReset, setFormModalVisible, setFormDisabled]);

  const onSecurityCodeFormSubmit = useCallback((data: {logonId: string, password: string, environmentType: XledgerEnvironmentType }) => {
    (async () => {
      const { logonId, password, environmentType } = data;
      const result = await callRemoteApi(() =>
        xledgerApiClient?.certificateAcquisitionEndpointsGetSecurityCode({
          logonId: logonId,
          password: password,
          environmentType: environmentType,
        }),
      );

      if (result !== undefined) {
        dispatcher(
          notificationsActionCreators.addNotification({
            type: NotificationType.SUCCESS,
            messageId: 'xledger-certificate-form-security-code-sent-message',
          }),
        );

        setSecurityCodeSent(true);
      }
    })();
  }, [xledgerApiClient, setSecurityCodeSent, callRemoteApi, dispatcher]);

  const onCertificateFormSubmit = useCallback(
    (data: {securityCode: string}) => {
      (async () => {

        const { logonId, password, environmentType } = getSecurityCodeFormValues();

        const result = await callRemoteApi(() =>
          xledgerApiClient?.certificateAcquisitionEndpointsGetCertificate({
            logonId: logonId,
            password: password,
            environmentType: environmentType as XledgerEnvironmentType,
            securityCode: data.securityCode,
          }),
        );

        if (!result ) { return; }

        dispatcher(
          notificationsActionCreators.addNotification({
            type: NotificationType.SUCCESS,
            messageId: 'xledger-certificate-form-certificate-received-success-message',
          }),
        );

        onCertificateAcquiredCallback({
          certificateDataEncoded: result!.certificateBase64Encoded,
          certificatePassword: result!.certificatePassword,
        });

        closeModal();

      })();
    },
    [xledgerApiClient, callRemoteApi, getSecurityCodeFormValues, onCertificateAcquiredCallback, setFormModalVisible],
  );

  return (
    <>
      <Button primary disabled={disabled} onClick={onAcquireCertificateClickHandler}>
        <SslCertificateIcon /><Message id={'xledger-certificate-form-get-new-certificate'} />
      </Button>
      <Modal
        hideOnOverlayClicked={false}
        closeOnEsc={true}
        isVisible={isFormModalVisible}
        title={<Message id={'xledger-certificate-form-modal-dialog-title'} />}
        onCloseClicked={closeModal}
      >
        {isFetching && <Spinner />}

        <FormWrapper onSubmit={(e: any) => e.preventDefault()}>
          <FormColumnWrapper>
            <FormColumn>

              <FormRow>
                <Controller
                  control={securityCodeFormControl}
                  name='logonId'
                  defaultValue={defaultValues.logonId}
                  rules={{
                    required: true,
                    minLength: 5,
                    maxLength: 100,
                  }}
                  render={({ field, fieldState }) => {
                    const messageId = fieldState.error?.message
                      ? fieldState.error.message
                      : 'xledger-certificate-form-logon-id-field-error';

                    return (
                      <Input
                        isRequired
                        disabled={isFormDisabled}
                        isError={fieldState.invalid}
                        id='logonId'
                        type='text'
                        label={<Message id='xledger-certificate-form-logon-id-field-label' />}
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        errorText={<Message id={messageId} />}
                      />
                    );
                  }}
                />
              </FormRow>

              <FormRow>
                <Controller
                  control={securityCodeFormControl}
                  name='password'
                  defaultValue={defaultValues.password}
                  rules={{
                    required: true,
                    minLength: 1,
                    maxLength: 70,
                  }}
                  render={({ field, fieldState }) => {
                    const messageId = fieldState.error?.message
                      ? fieldState.error.message
                      : 'xledger-certificate-form-password-field-error';
                    return (
                      <Input
                        isRequired
                        disabled={isFormDisabled}
                        isError={fieldState.invalid}
                        id='password'
                        textAlign='left'
                        type='password'
                        label={<Message id={'xledger-certificate-form-password-field-label'} />}
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        errorText={<Message id={messageId} />}
                      />
                    );
                  }}
                />
              </FormRow>

              <FormRow>
                <Controller
                  control={securityCodeFormControl}
                  name='environmentType'
                  defaultValue={defaultValues.environmentType}
                  rules={{
                    required: true,
                    minLength: 1,
                    maxLength: 70,
                    validate: (value) => Object.values(XledgerEnvironmentType)
                      .map(x => x.toLocaleLowerCase())
                      .includes(value.toLocaleLowerCase()),
                  }}
                  render={({ field, fieldState }) => {
                    const messageId = fieldState.error?.message
                      ? fieldState.error.message
                      : 'xledger-certificate-form-environment-type-field-error';
                    return (
                      <Input
                        isRequired
                        disabled={isFormDisabled}
                        isError={fieldState.invalid}
                        id='environmentType'
                        textAlign='left'
                        type='text'
                        label={<Message id={'xledger-certificate-form-environment-type-field-label'} />}
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        errorText={<Message id={messageId} />}
                      />
                    );
                  }}
                />
              </FormRow>

              <FormRow>
                <Button
                  disabled={!securityCodeFormIsDirty || !securityCodeFormIsValid || isFormDisabled}
                  primary
                  onClick={securityCodeFormHandleSubmit(onSecurityCodeFormSubmit)}
                >
                  <Message id={'xledger-certificate-form-get-security-code-button-title'} />
                </Button>
              </FormRow>

            </FormColumn>
          </FormColumnWrapper>
        </FormWrapper>

        <FormWrapper onSubmit={(e: any) => e.preventDefault()}>
          <FormColumnWrapper>
            <FormColumn>

              <FormRow>
                <Controller
                  control={certificateFormControl}
                  name='securityCode'
                  defaultValue={defaultValues.securityCode}
                  rules={{
                    required: true,
                    minLength: 4,
                    maxLength: 4,
                  }}
                  render={({ field, fieldState }) => {
                    const messageId = fieldState.error?.message
                      ? fieldState.error.message
                      : 'xledger-certificate-form-security-code-field-error';

                    return (
                      <Input
                        isRequired
                        disabled={!securityCodeSent || isFormDisabled}
                        isError={fieldState.invalid}
                        id='securityCode'
                        type='text'
                        label={<Message id={'xledger-certificate-form-security-code-field-label'} />}
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        errorText={<Message id={messageId} />}
                      />
                    );
                  }}
                />
              </FormRow>

            </FormColumn>
          </FormColumnWrapper>

          <FormButtons>
            <Button transparent onClick={closeModal}>
              <Message id={'cancel-button'} />
            </Button>
            <Button
              disabled={!certificateFormIsValid || !certificateFormIsDirty || !securityCodeFormIsValid || !securityCodeFormIsDirty || isFormDisabled }
              primary
              onClick={certificateFormHandleSubmit(onCertificateFormSubmit)}
            >
              <Message id={'xledger-certificate-form-get-certificate-button-title'} />
            </Button>
          </FormButtons>

        </FormWrapper>
      </Modal>
    </>
  );
};

export default XledgerCertificateForm;
