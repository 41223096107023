import React, { ReactNode } from 'react';
import { TooltipCustom } from '../index';
import { Wrapper, Slider, Input, Inner, LeftLabel, RightLabel, SwitcherContainer, SwitcherLabel } from './styles';

interface SwitcherProps {
  isChecked: boolean;
  isDisable?: boolean;
  switcherRef?: any;
  text?: ReactNode;
  tooltipText?: ReactNode;
  leftLabel?: string | ReactNode;
  rightLabel?: string | ReactNode;
  onChange: (event?: any) => void;
  handleOnClick?: (event: any) => void;
}

// eslint-disable-next-line react/display-name
export const Switcher = React.forwardRef(
  (
    {
      isChecked,
      isDisable,
      text,
      tooltipText,
      leftLabel,
      rightLabel,
      onChange,
      handleOnClick,
    }: // ...rest
    SwitcherProps,
    switcherRef,
  ) => {
    return (
      <Wrapper>
        {text && (
          <SwitcherLabel>
            {tooltipText ? (
              <TooltipCustom placement='bottom' title={tooltipText}>
                <span>{text}</span>
              </TooltipCustom>
            ) : (
              <span>{text}</span>
            )}
          </SwitcherLabel>
        )}
        <SwitcherContainer onClick={handleOnClick}>
          {leftLabel && <LeftLabel>{leftLabel}</LeftLabel>}
          <Inner>
            <Input
              // @ts-ignore
              ref={switcherRef}
              type='checkbox'
              checked={isChecked}
              onChange={onChange}
              disabled={isDisable}
            />
            <Slider isDisable={isDisable} />
          </Inner>
          {rightLabel && <RightLabel>{rightLabel}</RightLabel>}
        </SwitcherContainer>
      </Wrapper>
    );
  },
);

export default Switcher;
