import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { SyncMasterDataStatus } from 'types/SyncMasterData';

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
  margin: 0;
`;

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
`;

export const FiltersWrapper = styled.div`
  position: sticky;
  top: 56px;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  background: ${({ theme }) => theme.backgroundSecondary};
`;

export const FiltersLeft = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1 1 auto;
`;

export const FiltersRight = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: 12px;

  & > button {
    margin-left: 10px;
  }
`;

export const FiltersColumn = styled.div`
  max-width: 200px;
  margin-right: 24px;
  width: 100%;

  &:last-child {
    margin-right: 0;
  }
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.linkColor};
`;

interface StatusLabelType {
  status: string;
}

const getStatusColor = (theme: any, status: string) => {
  if (status === SyncMasterDataStatus.SUCCESS) {
    return theme.status.success;
  }
  if (status === SyncMasterDataStatus.START) {
    return theme.status.pending;
  }
  if (status === SyncMasterDataStatus.PARTIAL_SUCCESS) {
    return theme.status.warning;
  }
  if (status === SyncMasterDataStatus.FAILURE) {
    return theme.status.error;
  }

  return theme.status.info;
};

export const StatusLabel = styled.span<StatusLabelType>`
  font-weight: 600;
  color: ${({ theme, status }) => getStatusColor(theme, status)};
`;

export const FormButtonsCentered = styled.div`
  display: flex;
  justify-content: center;

  & > button {
    min-width: 100px;
    margin: 0 10px;
  }
`;

export const ModalText = styled.p`
  font-weight: 400;
  margin: 4px 0 0;
`;

export const ModalTextMark = styled.span`
  display: inline-block;
  font-size: 12px;
  line-height: 24px;
  color: ${({ theme }) => theme.fontSecondary};
`;

export const ModalSection = styled.section`
  position: relative;
  margin-bottom: 32px;
`;

export const ModalSelectedClients = styled.ul`
  display: flex;
  list-style: none;
  flex-direction: column;
  padding: 6px;
  gap: 8px;
  max-height: 200px;
  overflow-y: auto;
`;

export const ModalSelectedClient = styled.li<{ onlySelected: boolean }>`
  position: relative;
  display: block;
  border-radius: 6px;
  margin-right: 8px;
  background: rgba(0, 0, 0, 0.025);
  padding: ${({ onlySelected }) => (onlySelected ? '18px' : '6px')};
  font-size: ${({ onlySelected }) => (onlySelected ? '16px' : '12px')};
`;

export const ModalSelectedClientRemoveButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  padding: 6px 4px;
  cursor: pointer;
  color: rgb(255, 0, 0, 0.75);
  background: inherit;
  border: none;

  :hover {
    background: inherit;
  }

  :disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const ModalSelectFromDateTitle = styled.h3`
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 2px;
  margin-bottom: 20px;
`;

export const ModalSelectFromDateTitleTooltip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  font-size: 12px;
  font-color: rgba(0, 0, 0, 0.75);
  background: rgba(0, 0, 0, 0.035);
  border-radius: 2px;
  cursor: help;
`;

export const ModalSelectFromDateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const ModalSelectStrategyAlert = styled.div`
  display: block;
`;

export const ModalSelectStrategyOptions = styled.ul`
  list-style: none;
  display: flex;
  gap: 12px;
  padding: 0;
`;

export const ModalSelectStrategyOption = styled.li<{ selected: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 120px;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.65);
  opacity: ${({ selected }) => (selected ? '1' : '1')};
  border: 3px solid ${({ selected }) => (selected ? '#2f94d9' : 'rgba(0, 0, 0, 0.1)')};
  border-radius: 4px;
  cursor: pointer;

  :hover {
    border-color: #2f94d9;
  }
`;

export const ModalSelectStrategyOptionIcon = styled.div`
  display: block;
  margin-bottom: 12px;
`;

export const ModalSelectStrategyOptionText = styled.h6`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
`;

export const ActionButtons = styled.span`
  display: flex;
  justify-content: flex-end;
`;

export const ErrorBlock = styled.div`
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  p {
    margin: 0;
  }
`;

export const ErrorTitle = styled.h4`
  font-size: 13px;
  line-height: 21px;
  font-wight: 600;
  margin: 0 0 5px;
`;

export const ErrorText = styled.span`
  font-size: 13px;
  line-height: 21px;

  span:after {
    content: ',';
  }

  &:last-child span:after {
    display: none;
  }
`;

export const DateWrap = styled.div`
  overflow: hidden;
  user-select: text;
`;
