import styled from 'styled-components';

export const FormWrapper = styled.form`
  padding: 10px;
`;

export const FormColumnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FormColumn = styled.div`
  width: 100%;
  flex: 0 0 100%;
  text-align: left;
`;

export const FormRow = styled.div`
  margin-bottom: 20px;
`;

export const FormButtons = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px 0 0;

  & > button {
    margin-left: 20px;
    min-width: 130px;
  }
`;
