import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ActionWithPayload } from 'store/Action';
import { clientsService } from 'common/api/clientsService';
import { actionCreators as notificationsActionCreators } from 'components/NotificationManager/logic/notificationReducer';
import { NotificationType } from 'types/Notifications';
import { SyncClientData, SyncedDataItem } from 'types/SyncMasterData';
import { TableSortOrder } from 'types/Tables';
import { RootState } from 'store/rootReducer';
import { ActionTypeKeys, actionCreators } from './syncMasterDataReducer';

export const getPage = (state: RootState) => state.syncMasterData.page;
export const getPerPage = (state: RootState) => state.syncMasterData.perPage;
export const getFilterSearch = (state: RootState) => state.syncMasterData.filterSearch;
export const getOrderBy = (state: RootState) => state.syncMasterData.orderBy;
export const getSortOrder = (state: RootState) => state.syncMasterData.sortOrder;

export function* getClientsForSyncMasterDataSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(clientsService.getClientsForSyncMasterData, payload);

    const prepareData = {
      data: data.data.map((item: SyncClientData) => ({
        ...item,
        key: item.organizationNumber,
        id: item.clientId,
      })),
      totalCount: data.totalCount,
    };

    yield put(actionCreators.getClientsForSyncMasterDataSuccess(prepareData));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getClientsForSyncMasterDataFailed());
  }
}

export function* executeSyncMasterDataSaga({ payload }: ActionWithPayload) {
  try {
    const currentPage: number = yield select(getPage);
    const currentPerPage: number = yield select(getPerPage);
    const currentFilterSearch: string = yield select(getFilterSearch);
    const currentOrderBy: string = yield select(getOrderBy);
    const currentSortOrder: TableSortOrder = yield select(getSortOrder);
    const syncedData = [] as SyncedDataItem[];

    for (let i = 0; i < payload.clientIds.length; i++) {
      const { data } = yield call(clientsService.executeSyncMasterDataByClientId, {
        accessToken: payload.accessToken,
        customerId: payload.customerId,
        clientId: payload.clientIds[i],
        fromDate: payload.fromDate,
      });

      syncedData.push(data);
    }

    const syncResults: SyncedDataItem[] = syncedData.filter((item: SyncedDataItem) => !item.successful);

    yield put(actionCreators.executeSyncMasterDataSuccess(syncResults));

    yield put(
      actionCreators.getClientsForSyncMasterData({
        accessToken: payload.accessToken,
        customerId: payload.customerId,
        page: currentPage,
        size: currentPerPage,
        orderBy: currentOrderBy,
        sortOrder: currentSortOrder,
        search: currentFilterSearch,
      }),
    );
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal?.response?.data?.message || error,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.executeSyncMasterDataFailed());
  }
}

export function* syncMasterDataSaga() {
  yield* [
    takeEvery(ActionTypeKeys.GET_CLIENTS_FOR_SYNC_MASTER_DATA, getClientsForSyncMasterDataSaga),
    takeEvery(ActionTypeKeys.EXECUTE_SYNC_MASTER_DATA, executeSyncMasterDataSaga),
  ];
}
